import {APP_MESSAGE_TYPE_DBLOADED} from './../actions/app_a';
import {initialState}  from './../../initialState.js';

export function appReducer(state = initialState, action) {
  switch(action.type) {
		case APP_MESSAGE_TYPE_DBLOADED: 
    	return {
				...state,		
				db: action.message.data
		}    		
		default: 
			return state;
	}
}
export const getDB = state => state.app.db;
