import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';


import {createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { Provider } from 'react-redux';
import { Route} from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";

import App from 'App';
import './index.css';

import {apiReducer} from "_redux/reducers/api_r";
import {appReducer} from "_redux/reducers/app_r";
import {initialState} from 'initialState';

const middlewares = [thunk];
const rootReducer = combineReducers({apiReducer, app:appReducer});
const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

ReactDOM.render(
	<Provider store={store}>
		<HashRouter> 
			<I18nextProvider i18n={i18n}>
				<Route path="/" component={App}  />
			</I18nextProvider>
		</HashRouter> 	
	</Provider>
	,
  document.getElementById('root')
);
