import React, {Component} from 'react';

import "./Versionselector.css";
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';
import { Link, Redirect, withRouter } from 'react-router-dom';
import {version} from '../../../package.json';
class Versionselector extends Component
{
	constructor(props) {
    super(props);
		
		this.selectedVersion = "selectedVersion";
		this.clicked = this.clicked.bind(this);
		this.documentClicked = this.documentClicked.bind(this);
		this.keyPressed = this.keyPressed.bind(this);
		document.addEventListener("click", function(e){
			this.documentClicked(e);
		}.bind(this));
		document.addEventListener("keydown", function(e){
			this.keyPressed(e);
		}.bind(this));

		this.state = {shown:false, reload:false};
	}

	documentClicked(e)
	{
		if(e.ctrlKey)
			this.setState({shown:true})
	}

	keyPressed (e) {
		
    if(e.key === "Escape" || e.key === "Esc" || e.keyCode == 27) {
			this.setState({shown:true});
    }
}

	clicked(id)
	{		
		localStorage.setItem(this.selectedVersion, id);
		this.setState({reload:true})
	}
	render()
	{
		if(this.state.reload)
		{
			if(this.props.location.pathname === "/")
			{
				this.state.reload=false;
				window.location.reload();
			}
			else
				return(<Redirect to="/"></Redirect>);
		}
	
		let id = localStorage.getItem(this.selectedVersion);
		return(
			<div className={"Versionselector"+(this.state.shown?"":" hidden")}>
				<div className="content">
					<span>Select app:</span>
					<div className="Versionselectorlist">
						<div className={id===""?"active":""} onClick={()=>this.clicked("")}>auto</div>
						<div className={id==="_1.3.1.1"?"active":""} onClick={()=>this.clicked("_1.3.1.1")}>1.3.1.1 - Religionstopografie</div>
						<div className={id==="_1.2.3.2"?"active":""} onClick={()=>this.clicked("_1.2.3.2")}>1.2.3.2 - Projektionstisch 1: Heimatatlas</div>
						<div className={id==="_2.2.10"?"active":""} onClick={()=>this.clicked("_2.2.10")}>2.2.10 - Sprach Quiz</div>
						<div className={id==="_2.1.5"?"active":""} onClick={()=>this.clicked("_2.1.5")}>2.1.5 - Bedeutende Sudetendeutsche</div>
						<div className={id==="_2.3.1"?"active":""} onClick={()=>this.clicked("_2.3.1")}>2.3.1 - Projektionstisch 2</div>
						<div className={id==="_3.4.5.2"?"active":""} onClick={()=>this.clicked("_3.4.5.2")}>3.4.5.2 - KZ Lagersystem</div>
						<div className={id==="_4.1.1.5"?"active":""} onClick={()=>this.clicked("_4.1.1.5")}>4.1.1.5 - Sammellagersystem</div>
						<div className={id==="_9.0"?"active":""} onClick={()=>this.clicked("_9.0")}>9.0 - Schrank</div>
						<div className={id==="_9.1"?"active":""} onClick={()=>this.clicked("_9.1")}>9.1 - Schrank - Sonderausstellung</div>
					</div>
					<small>App Version: {version}</small>
				</div>
			</div>			
		);
	}
}
export default withRouter(Versionselector);