export const CONST = {
	_empty:'',
	de:'de',
	en:'en',
	cz:'cz',
	home:'home',
	info:'info',
	imprint:'imprint',
	list:'list',
	div:'div',
	span:'span',
	p:'p',
	location:'location',
	slash:'/',
	space:' ',
	zero:'0',
	colon:':'

	
};