import XLSX from 'xlsx';
import { CONST } from '../Constants';

let _columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];


function parse(sheetName, sheet)
{
	/*#region maxColumns*/
	let notfoundColumns = 0;
	let maxCols = 0;
	for(let c=0; c<500;c++)
	{
		let cell = sheet[getCellName(0,c)];
		let cell_1 = sheet[getCellName(2,c)];
		let cell_2 = sheet[getCellName(3,c)];
		if(cell == null && cell_1==null && cell_2==null)
			notfoundColumns++;
		else
			notfoundColumns = 0;
		if(notfoundColumns>10)
		{
			maxCols = c-notfoundColumns+1;
			break;
		}
	}
	if(maxCols === 0)
		maxCols = 500;
	/*#endregion maxColumns*/
	
	/*#region maxRows*/
	let maxRows = 0;
	let notfoundRows = 0;
	for(let r=0; r<5000;r++)
	{
		let cell = sheet[getCellName(r,0)];
		if(cell == null)
			notfoundRows++;
		else
		notfoundRows = 0;
		if(notfoundRows>10)
		{
			maxRows = r-notfoundRows;
			break;
		}
	}
	if(maxRows === 0)
		maxRows = 5000;
	/*#endregion maxRows*/

	let data = [];
	let cell = null;
	
	for(let r=0;r<=maxRows;r++)
	{
		data[r]= [];
		for(let c=0;c<maxCols;c++)
		{
			cell = sheet[getCellName(r,c)];
			data[r][c] = cell!=null?cell.v:CONST._empty;
		}
	}

	return {name:sheetName, db:data};
}

function getCellName(row, column)
{
	return getColumnNameById(column)+getRowNameById(row);
}

function getColumnNameById(id)
{
	if(id<_columns.length)
	{
		return _columns[id];
	}
	else
	{
		let name = "";
		name = _columns[Math.floor(id/26)-1];
		name += _columns[Math.floor(id%26)];
		return name;
	}
}

function getRowNameById(id)
{
	return id+1;
}

//let _callback;
export function Load(url, callback)
{
//	_callback = callback;
	var oReq = new XMLHttpRequest();
	oReq.open("GET", url, true);
	oReq.responseType = "arraybuffer";
	oReq.onload = function(e) {
		
			var arraybuffer = oReq.response;
			var data = new Uint8Array(arraybuffer);
			var arr = [];
			for (var i = 0; i <= data.length; ++i)
					arr[i] = String.fromCharCode(data[i]);

			var bstr = arr.join("");
			try
			{
				var cfb = XLSX.read(bstr, { type: 'binary' });
				let sheets = [];

				cfb.SheetNames.forEach(function(sheetName, index) {
					let xs = parse(sheetName, cfb.Sheets[sheetName]);
					sheets.push(xs);
				});
				
				callback({name:url, db:sheets});
			}
			catch(e)
			{
				callback({name:url, db:[]})
			}
			
	}

	oReq.send();
}


