
import React, { Component, Suspense } from 'react';
import {Redirect} from 'react-router-dom';

import {Load} from '_models/ExcelParser.js';

import './App.css';
import './fonts/fonts.css';



import { CONST } from './Constants';
import i18n from './i18n';
import proj4 from 'proj4';
import Versionselector from './ui/Versionselector/Versionselector';
import i18next from 'i18next';


const ProjektionsTisch_1_2_3_2 = React.lazy(() => import('./ui/apps/_1_2_3_2/ProjektionsTisch_1_2_3_2'));
const Religionstopografie_1_3_1_1 = React.lazy(() => import('./ui/apps/_1_3_1_1/Religionstopografie_1_3_1_1'));
const BedeutendeSD_2_1_5 = React.lazy(() => import('./ui/apps/_2_1_5/BedeutendeSD_2_1_5'));
const Quiz_2_2_10 = React.lazy(() => import('./ui/apps/_2_2_10/Quiz_2_2_10'));
const ProjektionsTisch_2_3_1 = React.lazy(() => import('./ui/apps/_2_3_1/ProjektionsTisch_2_3_1'));
const KZLagersystem_3_4_5_2 = React.lazy(() => import('./ui/apps/_3_4_5_2/KZLagersystem_3_4_5_2'));
const SammelLagersystem_4_1_1_5 = React.lazy(() => import('./ui/apps/_4_1_1_5/SammelLagersystem_4_1_1_5'));
const Schrank_9_0 = React.lazy(() => import('./ui/apps/_9_0/Schrank_9_0'));

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { width: window.innerWidth, height: window.innerHeight, scale:1 };
		document.addEventListener('contextmenu', function(event) {event.preventDefault();}, true); 
		this.rawDbLoaded = this.rawDbLoaded.bind(this);
		this.rawTranslationsLoaded = this.rawTranslationsLoaded.bind(this);
		
		if(!this.initialized)
		{
			i18n.changeLanguage(CONST.de);
			this.initialized=true;
		}

		this.app_resolutions={};
		this.app_resolutions["_1.2.3.2"] = {w:1920,h:1200};
		this.app_resolutions["_1.3.1.1"] = {w:1920,h:1080};
		this.app_resolutions["_2.1.5"] = {w:1920,h:1080};
		this.app_resolutions["_2.2.10"] = {w:1920,h:1080};
		this.app_resolutions["_2.3.1"] = {w:1920,h:1200};
		this.app_resolutions["_3.4.5.2"] = {w:1920,h:1080};
		this.app_resolutions["_4.1.1.5"] = {w:1920,h:1080};
		this.app_resolutions["_9.0"] = {w:1920,h:1080};
		this.app_resolutions["_9.1"] = {w:1920,h:1080};
		
	}

	updateDimensions() {
   
		let appsize = this.app_resolutions[this.appid];
		if(appsize == null)
			appsize = {w:1920, h:100};
		let scale = 1;
		if(appsize.w/appsize.h>window.innerWidth/window.innerHeight)
			scale = window.innerWidth/appsize.w;
		else
			scale = window.innerHeight/appsize.h;
    this.setState({ width: appsize.w, height: appsize.h, scale:scale });    
	}
	
	interactionFound(e)
	{
		if(this.timerInterval!=null)
		{
			clearInterval(this.timerInterval);
		}
		this.timerInterval = setInterval(function(){this.setState({startIdle:true})}.bind(this), this.idleTime*1000);
		
		if(document.location.hash.includes("/idle"))
		{
			let link = "/"
			if(e.target.classList.contains("Idle"))	
				link="/";
			else if(e.target.classList.contains("FooterButton"))	
				link = "/";
			else if(e.target.parentNode.classList.contains("FooterButton"))	//leftFooterIcons
				link = "";
			if(link!==CONST._empty)
			{
				setTimeout(()=>{this.setState({closeIdle:true, link:link})}, 200);
			}
		}
	}

	componentDidMount()
	{
		this.updateDimensions();
		
		let id = localStorage.getItem("selectedVersion");
    console.log(id)
		if(id == null || id=="")
    {
      id = "";
      
    }
    console.log("Click CTRL + Left Mouse to select/change the app version")
    
	//	id = "_1.3.1.1";
		fetch('settings'+id+'.json').then((r) =>  r.json())
		.then((data) =>{			
			this.settings = data;
			this.idleTime = data.idleTime;
			if(this.idleTime<10)
				this.idleTime = 10;
			
			let url = data.db_file;
			Load(url, this.rawDbLoaded);

			
			let url1 = data.translation_file;
			Load(url1, this.rawTranslationsLoaded);
		});
		this.appid = id;

		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions.bind(this));	
	}

	rawTranslationsLoaded(data)
	{
		let ide = i18n.getResourceBundle(CONST.de);
		let ien = i18n.getResourceBundle(CONST.en);
		let icz = i18n.getResourceBundle(CONST.cz);
		if(data.db[0] == null)
		{
			this.setState({ ...this.state, rawDB:true, transitionsLoaded:true});    
			return;
		}
		for(let r = 1;r<data.db[0].db.length;r++)
		{ 
			ide[data.db[0].db[r][0]] = data.db[0].db[r][1];
			icz[data.db[0].db[r][0]] = data.db[0].db[r][2];
			ien[data.db[0].db[r][0]] = data.db[0].db[r][3];
		}
	
		document.addEventListener("mousedown", function(e){this.interactionFound(e)}.bind(this));	
		document.addEventListener("touchstart", function(e){this.interactionFound(e)}.bind(this));	
		this.timerInterval = setInterval(function(e){this.setState({startIdle:true})}.bind(this), this.idleTime*1000);

		this.setState({ ...this.state, transitionsLoaded:true});    
	}

	rawDbLoaded(data)
	{				
		
		this.setState({ ...this.state, rawDB:data});    
	}

	componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
	}
	
  render() {
	
		if(this.state && this.state.startIdle)
		{
			i18n.changeLanguage(CONST.de);
			this.state.startIdle = false;
			return(<Redirect to="/idle"></Redirect>)
		}
		else if(this.state && this.state.closeIdle)
		{
			this.state.closeIdle = null;
			return(<Redirect to="/"></Redirect>)
		}
		if(!this.state.transitionsLoaded || !this.state.rawDB)
			return("Loading...");

		document.documentElement.lang = i18next.language;
		
		return (
      <div className={"App " +"_"+this.settings.id} style={{"--app_w":this.state.width+"px", "--app_h":this.state.height+"px", "--scale":this.state.scale}}>
				<Suspense fallback={<div>Loading...</div>}>
					{this.settings.id === "1_2_3_2" && this.state.rawDB!=true?<ProjektionsTisch_1_2_3_2 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></ProjektionsTisch_1_2_3_2>:""}
					{this.settings.id === "1_3_1_1" && this.state.rawDB!=true?<Religionstopografie_1_3_1_1 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></Religionstopografie_1_3_1_1>:""}
					{this.settings.id === "2_1_5" && this.state.rawDB!=true?<BedeutendeSD_2_1_5 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></BedeutendeSD_2_1_5>:""}
					{this.settings.id === "2_2_10" && this.state.rawDB!=true?<Quiz_2_2_10 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></Quiz_2_2_10>:""}
					{this.settings.id === "2_3_1" && this.state.rawDB!=true?<ProjektionsTisch_2_3_1 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></ProjektionsTisch_2_3_1>:""}
					{this.settings.id === "3_4_5_2" && this.state.rawDB!=true?<KZLagersystem_3_4_5_2 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></KZLagersystem_3_4_5_2>:""}
					{this.settings.id === "4_1_1_5" && this.state.rawDB!=true?<SammelLagersystem_4_1_1_5 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></SammelLagersystem_4_1_1_5>:""}
					{this.settings.id === "9_0" && this.state.rawDB!=true?<Schrank_9_0 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder}></Schrank_9_0>:""}
					{this.settings.id === "9_1" && this.state.rawDB!=true?<Schrank_9_0 rawDB={this.state.rawDB} contentPath={this.settings.app_content_folder} special={true}></Schrank_9_0>:""}
					
				</Suspense>
				<Versionselector></Versionselector>
		  </div>		
    );
  }
}

export default App;