export const initialState = {

	apiReducer:	{
		api_jobs:0
	},
	app:
	{
		messages:[],
		db:null
	}
}